/*@import "bootstrap/dist/css/bootstrap.css";*/
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  margin: 1rem;
  margin-top: 0px;
  height: 90vh;
}

.map-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  margin-right: 20px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
}

.legend {
  background-color: #000000;
  color: #ffffff;
  border-radius: 3px;
  bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 1;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}


#menu {
  background: #fff;
  /* position: absolute; */
  /* z-index: 1; */
  top: 15px;
  left: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: 'Open Sans', sans-serif;
  padding: 10px;
  width: 30%;
  min-width: 360px;
}

label {
  font-size: 15px;
}

#menu a {
  font-size: 13px;
  color: #404040;
  display: block;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

.menu-items {
  margin: 10px
}

#menu a:last-child {
  border: none;
}

#menu a:hover {
  background-color: #f8f8f8;
  color: #404040;
}

#menu a.active {
  background-color: #3887be;
  color: #ffffff;
}

#menu a.active:hover {
  background: #3074a4;
}

.top-left {
  position: relative;
  top: 10px;
  left: 10px;
}

.over-map {
  z-index: 1;
  position: absolute;
  cursor: pointer;
}

.hide {
  display: none;
}

.bottom-center {
  left: 10px;
  bottom: 10px;
}

.regression {
  fill: none;
  stroke: blue;
  stroke-width: 1.5px;
}

.circle-selected {
  stroke: black;
  stroke-width: 2.5;
  stroke-opacity: 1;
  fill: rgb(0, 255, 0);
  opacity: 1;
}

#emissions-table {
  display: block;
  overflow: auto;
}

.table {
  /* table-layout: fixed; */
  border-collapse: collapse;
  display: block;
  width: 100%;
  height: 50vh;
  border: 1px solid;
  /*  border: 2px solid rgb(200, 200, 200);*/
  letter-spacing: 1px;
  font-size: 0.8rem;
  overflow: auto;
}
.table-methods {
  font-size: large;
  border: 1px black;
}
td {
  padding-left:20px;
}


.td,
.th {
  /*  border-right: 1px solid rgb(190, 190, 190);*/

  padding: 10px 20px;
  width: 100px;
}

.th {
  background-color: rgb(235, 235, 235);
  position: sticky;
}

.td {
  text-align: center;
}

.tr:nth-child(even) .td {
  background-color: rgb(250, 250, 250);
}

.tr:nth-child(odd) .td {
  background-color: rgb(245, 245, 245);
}


.table .tr .td {
  max-width: 100%;
  overflow: auto;
}

thead {
  position: sticky;
  top: 0;
  border-bottom: 2px solid #ccc;
  background: #eee;
}


#nav-content {
  display: flex;
  padding-left: 1%;
  width: 100vw;
}

#nav-title {}

#nav-links {
  padding-left: 2%;
  font-size: x-large;
}

.home-content {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 1%;
}

p {
  font-size: x-large;
}

#loading-div {
  left: 30%;
  top: 40%;
  position: relative;
}

.selected_row {
  border : 2px solid darkred;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.5;
  z-index: 1002;
}

.disp-image {
  width: 100%;
}

.emissions-legend-title {
  display: flex;
  justify-content: space-between;
}

.info-icon {
  height: 30px;
  width: 30px;
}

.legend-tooltip {
  display: none;
  position: absolute
}

.info-icon:hover~.legend-tooltip {
  display: block
}

.map-popup {
  width: 400px;
  height: 270px;
  max-width: 400px!important;
  z-index: 1000;
}

.map-popup-title {
  text-align: center;
}